<template>
  <!--位置区域组件-->
  <div class="cd_out p_f">
    <div class="ca_title_out d_f ali_c">
      <div class="cur_p">
        <img src="../img/close_sp.png" alt="" class="cur_p" @click="closeDistance">
      </div>
      <div class="ca_title t_a flex_1">位置距离</div>
    </div>
    <div class="cd_content_out">
      <div class="cdc_title_out d_f ali_c">
        <div class="red_point"></div>
        <div class="cdc_title">位置距离</div>
      </div>
      <div class="cd_item_out">
        <div class="cd_item d_f ali_c j_s cur_p" v-for="(item,index) in distanceArr" :key="index" :class="chooseIndex === index ? 'ac' : ''" @click="chooseDistance(item,index)">
          <span>{{item.name}}</span>
          <img src="../img/cd_choose_distance.png" alt="">
        </div>
      </div>
    </div>
    <div class="bottom_btn_out t_a p_f d_f ali_c j_s">
      <div class="cur_p cd_btn" @click="resetDistance">重置</div>
      <div class="cur_p cd_btn ac" @click="saveDistance">确定</div>
    </div>
  </div>
</template>

<script>
  import apis from '../utils/apis'

  export default {
    name: "chooseDistance",
    data() {
      return {
        chooseIndex:0,
        distanceArr:[],
        distance: sessionStorage.getItem('distance') ? JSON.parse(sessionStorage.getItem('distance')) : null, // 选择距离
      }
    },
    mounted(){
      this.enumsList('distance',this.delDistance);
    },
    methods: {
      chooseDistance(row,index){
        this.chooseIndex = index;
      },
      // distance
      delDistance(info){
        // chooseIndex
        this.distanceArr = JSON.parse(JSON.stringify(info));
        if(this.distance){
          this.distanceArr.map((item,index) => {
            if(item.value == this.distance.value){
              this.chooseIndex = index
            }
          })
        }
      },
      enumsList(key,callback){
        this.$get({
          url: apis.enumsList,
          params: {
            key:key
          }
        }).then(rsp => {
          callback(rsp)
        });
      },
      //关闭
      closeDistance(){
        this.$emit('closeDistance')
      },
      // 重置
      resetDistance(){
        this.chooseIndex = 0;
        sessionStorage.removeItem('distance');
      },
      // 确定
      saveDistance(){
        sessionStorage.setItem('distance',JSON.stringify(this.distanceArr[this.chooseIndex]));
        this.$emit('closeDistance')
      },
    }
  }
</script>

<style scoped lang="scss">
  .cd_out{
    .cd_content_out{
      .cd_item_out{
        height: calc(100% - 75px);
        overflow: auto;
      }
      .cd_item{
        &.ac{
          color: #20C177;
          img{
            display: block;
          }
        }
        border-bottom: 1px solid #DEDEDE;
        height: 40px;
        line-height: 40px;
        font-size:13px;
        font-weight:500;
        color:rgba(62,62,62,1);
        img{
          width: 15px;
          display: none;
        }
      }
      .cdc_title_out{
        .red_point{
          width:3px;
          height:3px;
          background-color:rgba(254,121,77,1);
          margin-right: 5px;
          border-radius: 50%;
        }
        margin-bottom: 40px;
        font-size:13px;
        font-weight:500;
        color:rgba(30,30,30,1);
      }
      padding: 31px 21px;
      height: calc(100% - 76px - 51px);
    }
    background-color: white;
    padding: 0 0 76px 0;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 99;
  }
</style>
